import React from 'react'

function createMarkup(props) {
  return {__html: (props.html)};
}

function MyComponent(props) {
  return <div className={props?.fieldClass} dangerouslySetInnerHTML={createMarkup(props)} />;
}

const HtmlBox = ({fieldClass, text, step}) => (
  <div className={`form-group form-html ${step}`}>
      <div className="html-wrap">
          <MyComponent html={text} fieldClass={fieldClass}/>
      </div>
  </div>
);

export default HtmlBox